@use '../../../scss/' as *;
.img-box {
    @include transition3;
    position: relative;
    padding: 20px 20px 20px;  
    background: #0e2230;
    &::after {
        @include transition3;
        position: absolute;
        content: "";
        border: 1px solid rgba(66, 32, 138, 0.5);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &::before {
        @include transition3;
        position: absolute;
        content: "";
        border: 2px solid rgba(34, 183, 143, 0);
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }
    &.active,
    &:hover {
        box-shadow: $box-shadow;
        &::before {
            border-color: #42208a;
            border: 2px solid #42208a;
        }
    }
    
    &:hover {
        cursor: context-menu;
    }
}
.project-image {
    max-width: 100%; /* Establece el ancho máximo en 200px */
    height: auto; /* Mantiene la proporción de aspecto */
  }

.home-2 .img-box {
    margin-bottom: 30px;
}

.page-nft {
    .img-box {
        margin-bottom: 32px;
        
    }
    .tf-button {
        margin-top: 0;
    }
}






 
